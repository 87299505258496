module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://ce64e87d4d114559adbf82441647ceee@o452213.ingest.sentry.io/6144615","sampleRate":0.7,"environment":"production","enabled":true,"debug":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"duration":500},
    },{
      plugin: require('../slipway-theme/packages/slipway-spa-theme/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":10000,"linkImagesToOriginal":false,"quality":80,"withWebp":true}},{"resolve":"@raae/gatsby-remark-oembed","options":{"providers":{"include":["Instagram"]}}},{"resolve":"gatsby-remark-embed-video","options":{"width":680,"ratio":1.77,"height":400,"related":false,"noIframeBorder":true,"urlOverrides":[{"id":"youtube"}]}},{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-numbered-footnotes"},{"resolve":"gatsby-remark-smartypants"},{"resolve":"gatsby-remark-autolink-headers","options":{"elements":["h1","h2"],"icon":false}},{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"noreferrer"}}],"remarkPlugins":[null],"defaultLayouts":{},"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"]},
    },{
      plugin: require('../slipway-theme/packages/slipway-spa-theme/gatsby-browser.ts'),
      options: {"plugins":[],"name":"SyncTALK","title":"SyncTALK SyncCentral","description":"SyncTalk for Everyone","copyrightOwner":"Synchronous Health","copyrightLink":"https://sync.health","siteUrl":"https://synccentral.synctalk.us","icon":"content/theme/favicon.png","siteTheme":"spa-theme","basePath":"/","analytics":{"google":"UA-84061010-16"},"social":[{"name":"twitter","url":"https://twitter.com/Karla_SyncHlth"},{"name":"instagram","url":"https://www.instagram.com/karla_sync.health/"},{"name":"linkedin","url":"https://www.linkedin.com/company/synchronous-health-inc./"},{"name":"facebook","url":"https://www.facebook.com/SynchronousHealth"}],"themeOverrides":{"TestimonialsAvatar":"avatar.jpg","HeroBg":"synccentralpage.jpg","CustomerLogo":"synccentrallogo.png","HeroBackgroundPosition":"center","HideSynctalkLogo":true,"Overrides":"{\n  \"headerLogo\": {\n    \"overflow\": \"visible\"\n  }\n}\n"},"registrationLinks":{"staging":{"link":"https://synchealth.typeform.com/to/YGf9vdPD","host":"synccentral-staging.synctalk.us","cus":"jRITa66tPobGj3yvLQJ6","org":"global","regtype":"self"},"production":{"link":"https://synchealth.typeform.com/to/YGf9vdPD","host":"synccentral.synctalk.us","cus":"jRITa66tPobGj3yvLQJ6","org":"global","regtype":"self"}},"externalFooterLinks":[{"name":"Privacy Policy","url":"https://sync.health/privacy-policy","locale":"en-US"},{"name":"Terms of Use","url":"https://sync.health/terms-of-use","locale":"en-US"},{"name":"Política de privacidad","url":"https://sync.health/privacy-policy","locale":"es-US"},{"name":"Condiciones de uso","url":"https://sync.health/terms-of-use","locale":"es-US"}],"contentArticles":"content/articles","contentSpecialists":"content/specialists","contentPages":"content/pages","contentLocales":"content/locales","contentBenefits":"content/benefits","contentTestimonials":"content/testimonials","contentTheme":"content/theme","uiTheme":"slipway","sources":{"local":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SyncTALK SyncCentral","short_name":"SyncTALK","start_url":"/","background_color":"#8B3765","theme_color":"#8B3765","display":"standalone","icon":"content/theme/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4d2ae5eb63c13820328fd0f5ed21de6d"},
    },{
      plugin: require('../node_modules/@bestyled/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-84061010-16"},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"projectRoot":"/home/runner/work/slipway-synccentral/slipway-synccentral","configDir":".config"},
    }]
